<template>
    <div class="data-center">
        <template>
            <div class="operate-box">
                <div class="left-filter">
                    <div class="label">所属班级</div>
                    <el-select v-model="classPracticalInfo.banji_id" placeholder="请选择" clearable @change="getClassPracticalInfo">
                        <el-option v-for="item in classOptions" :key="item.banji_id" :label="item.banji_name" :value="item.banji_id">
                        </el-option>
                    </el-select>
                </div>
                <div class="right-filter">
                    <div class="label">实训名称</div>
                    <el-select v-model="classPracticalInfo.train_id" placeholder="请选择" clearable @change="getDetailInfo">
                        <el-option v-for="item in practicalOptions" :key="item.train_id" :label="item.train_name" :value="item.train_id">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div class="update-time">
                    <span>数据更新时间：2022-07-21 09:16:00</span>
                </div> -->
            </div>
        </template>
        <template>
            <div class="total-chart">
                <div class="view-number item">
                    <div class="total-title">总观看人数</div>
                    <div class="total-numb">{{ audience_data.total }}</div>
                    <div class="sum-chart audience"></div>
                    <div class="average-numb">平均在线人数 {{ audience_data.ave }}</div>
                </div>
                <div class="order-number item item1">
                    <div class="total-title">总订单数</div>
                    <div class="total-numb">{{ order_data.total }}</div>
                    <div class="sum-chart order"></div>
                    <div class="average-numb">平均订单数 {{ order_data.ave }}</div>
                </div>
                <div class="sales-number item item1">
                    <div class="total-title">总销售额</div>
                    <div class="total-numb">{{ sales_data.total }}</div>
                    <div class="sum-chart sales"></div>
                    <div class="average-numb">平均销售额￥ {{ sales_data.ave }}</div>
                </div>
            </div>
        </template>
        <template>
            <div class="view-order-sales-chart">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane name="first">
                        <span slot="label">观看人数 <i class="el-icon-date"></i></span>
                        <div class="view-chart item"></div>
                    </el-tab-pane>
                    <el-tab-pane name="second">
                        <span slot="label">订单数 <i class="el-icon-date"></i></span>
                        <div class="order-chart item"></div>
                    </el-tab-pane>
                    <el-tab-pane name="third">
                        <span slot="label">销售额 <i class="el-icon-date"></i></span>
                        <div class="sales-chart item"></div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </template>
        <template>
            <div class="rank-table">
                <div class="table-calss-title">{{ className }}</div>
                <div class="rank-table-item">
                    <div class="table-title">直播在线人数排名TOP10</div>
                    <div class="people-top-table">
                        <el-table :data="classRankData" style="width: 100%" :header-cell-style="{background:'#F2F2F9', color: '#5B5B6D'}">
                            <el-table-column prop="rank" label="排名" width="180" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.rank == 1"
                                    ><img
                                        style="width: 20px"
                                        :src="require('../../../assets/image/teacherDataCenter/list-one.png')"
                                        alt=""
                                    /></span>
                                    <span v-else-if="scope.row.rank == 2"
                                    ><img
                                        style="width: 20px"
                                        :src="require('../../../assets/image/teacherDataCenter/list-two.png')"
                                        alt=""
                                    /></span>
                                    <span v-else-if="scope.row.rank == 3"
                                    ><img
                                        style="width: 20px"
                                        :src="require('../../../assets/image/teacherDataCenter/list-three.png')"
                                        alt=""
                                    /></span>
                                    <span v-else>{{ scope.row.rank }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="nickname" label="学生名称"></el-table-column>
                            <el-table-column prop="online_total_number" label="人气峰值（人）"></el-table-column>
                            <el-table-column prop="in_online_number" sortable label="观看人数（人）"></el-table-column>
                            <el-table-column prop="order_num" sortable label="订单数"></el-table-column>
                            <el-table-column prop="sales" sortable label="销售额"></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-link
                                    type="primary"
                                    :underline="false"
                                    @click="viewDetails(scope.row)">查看详情</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { getBanjiAndTrain, getDetailData } from '@/utils/apis'
export default {
    name: "Index",
    data() {
        return {
            classPracticalInfo: {
                banji_id: "",
                train_id: '',
                mid_sort_name: 'in_online_number',
                sort_name: 'in_online_number',
                sort_type: '2'
            },
            classOptions: [],
            practicalOptions: [],
            className: '',
            audience_data: {},
            order_data: {},
            sales_data: {},
            activeName: 'first',
            classRankData: [],
            chartClassName: '.view-chart',
            inOnlineChartData: [],
            orderChartData: [],
            salesChartData: [],
        };
    },
    methods: {
        init() {
            this.getBanjiAndTrainData();
        },
        async getBanjiAndTrainData() {
            let res = await getBanjiAndTrain();
            this.classOptions = res.data
        },
        getClassPracticalInfo(value) {
            for (let i = 0; i < this.classOptions.length; i ++) {
                if (value === this.classOptions[i].banji_id) {
                    this.practicalOptions = this.classOptions[i].trainTeacher
                    this.classPracticalInfo.train_id == ''
                }
            }
        },
        async getDetailInfo() {
            if (this.classPracticalInfo.train_id != '') {
                let res = await getDetailData(this.classPracticalInfo);
                this.className = res.data.banji_name
                this.audience_data = res.data.audience_data;
                this.order_data = res.data.order_data;
                this.sales_data = res.data.sales_data;
                this.classRankData = res.data.list;
                this.inOnlineChartData = this.convertKey(res.data.top_30_list, 'in_online_number');
                this.orderChartData = this.convertKey(res.data.top_30_list, 'order_num');
                this.salesChartData = this.convertKey(res.data.top_30_list, 'sales');
                this.viewersNumberChart(this.chartClassName, this.inOnlineChartData)
            }
        },
        viewDetails(row) {
            let routeUrl = this.$router.resolve({
                name: "dataCenterReplayData",
                path: "/teacher/dataCenter/replayData",
                query: {user_id: row.user_id, train_id: this.classPracticalInfo.train_id},
            });
            window.open(routeUrl.href, "_blank");
        },
        handleClick(tab) {
            if (tab.name == 'first') {
                this.chartClassName = '.view-chart'
                this.$nextTick(() => {
                    this.viewersNumberChart(this.chartClassName, this.inOnlineChartData)
                })
            } else if (tab.name == 'second') {
                this.chartClassName = '.order-chart'
                this.$nextTick(() => {
                    this.viewersNumberChart(this.chartClassName, this.orderChartData)
                })
            } else if (tab.name == 'third') {
                this.chartClassName = '.sales-chart'
                this.$nextTick(() => {
                    this.viewersNumberChart(this.chartClassName, this.salesChartData)
                })
            }
            
        },
        convertKey(arr, key) {
            let newArr = []
            arr.forEach((item, index) => {
                let newObj = {};
                newObj.name = item.nickname
                if (key == 'in_online_number') {
                    newObj.value = item.in_online_number
                } else if (key == 'order_num') {
                    newObj.value = item.order_num
                } else if (key == 'sales') {
                    newObj.value = item.sales
                }
                newArr.push(newObj);
            })
            return newArr;
        },
        viewersNumberChart(chartClassName, chartData) {
            let viewersCharts = this.$echarts.init(document.querySelector(chartClassName));
            if (viewersCharts == null && viewersCharts == "" && viewersCharts == undefined) {
                viewersCharts.dispose();//销毁
            }
            let title = '观看人数排名TOP30';
            if (chartClassName == '.order-chart') title = '订单数排名TOP30';
            if (chartClassName == '.sales-chart') title = '销售额排名TOP30';
            let xData = []
            for (let x = 0; x < chartData.length; x ++) {
                xData.push(chartData[x].name)
            }
            let option = {
                title: {
                    text: title,
                    textStyle: {
                        color: '#060111',
                        fontSize: 14,
                    }
                },
                grid: {
                    left: 60,
                    right: 27,
                    bottom: 115
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                        shadowStyle: {
                            color: "#564CED",
                            opacity: 0.2,
                        },
                    },
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderWidth: "1",
                    borderColor: "rgba(0, 0, 0, 0.1)",
                    textStyle: {
                        color: "#fff",
                        fontSize: 14,
                        fontWeight: 400,
                    },
                    formatter: function (datas) {
                        if (chartClassName == '.view-chart') return datas[0].data.name + "<br/>" + '在线人数：' + datas[0].data.value;
                        if (chartClassName == '.order-chart') return datas[0].data.name + "<br/>" + '订单数：' + datas[0].data.value;
                        if (chartClassName == '.sales-chart') return datas[0].data.name + "<br/>" + '销售额：' + datas[0].data.value;
                    },
                },
                xAxis: {
                    data: xData,
                    axisTick: {
                        show: false, //不展示X坐标轴刻度
                    },
                    axisLabel: {  
                        interval: 0,
                        rotate: -24,
                        verticalAlign: 'top',
                        formatter: function(value) {  
                            return value.split("").join("\n");  
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        type: "bar",
                        name: "活跃度",
                        data: chartData,
                        barWidth: 16,
                        itemStyle: {
                            color: "#2821FC",
                        },
                    },
                ],
            };
            if (chartData.length != 0) viewersCharts.setOption(option)
            
        }
    },
    mounted() {
        this.init()
    },
};
</script>

<style scoped lang="scss">
.data-center {
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    padding: 0px 30px 0;
    .hidden-box {
        display: none;
    }
    .operate-box {
        display: flex;
        justify-content: flex-start;
        height: 90px;
        border-bottom: 1px solid #DADCE7;
        margin-bottom: 32px;
        .left-filter {
            display: flex;
            align-items: center;
            color: #332d42;
            font-size: 14px;
        }
        .right-filter {
            display: flex;
            align-items: center;
            color: #332d42;
            font-size: 14px;
            margin-left: 40px;
        }
        .update-time {
            display: flex;
            align-items: center;
            width: 230px;
            color: #332d42;
            font-size: 14px;
            margin-left: 36.1%;
        }
        .label {
            margin-right: 10px;
        }
        ::v-deep .el-select {
            width: 250px;
            .el-icon-arrow-up:before {
                content: "\e790";
                color: #342e43;
                font-size: 20px;
            }
        }
    }
    .total-chart {
        display: flex;
        justify-content: flex-start;
        
        .item{
            flex: 1;
            height: 235px;
            border: 1px solid #DADCE7;
            border-radius: 10px;
        }
        .item1 {
            margin-left: 26px;
        }
        .total-title {
            font-size: 16px;
            color: #332D42;
            margin: 20px 0 0 32px;
        }
        .total-numb {
            height: 24px;
            font-size: 30px;
            color: #332D42;
            margin: 25px 0 0 32px;
        }
        .sum-chart {
            height: 80px;
            margin: 10px 31px 0 26px;
            border-bottom: 1px solid #DADCE7;
        }
        .average-numb {
            font-size: 14px;
            color: #332D42;
            margin: 15px 0 0 27px;
        }
        .audience {
            background: url('../../../assets/image/teacherDataCenter/audience-img.png') no-repeat;
            background-size: 448px;
        }
        .order {
            background: url('../../../assets/image/teacherDataCenter/order-img.png') no-repeat;
            background-size: 448px;
        }
        .sales {
            background: url('../../../assets/image/teacherDataCenter/sales-img.png') no-repeat 16px 0;
            background-size: 448px;
        }
    }
    .view-order-sales-chart {
        height: 534px;
        border: 1px solid #DADCE7;
        border-radius: 6px;
        margin-top: 20px;

        .item {
            margin-left: 31px;
            height: 534px;
        }

        ::v-deep .el-tabs__nav {
            margin-left: 32px;
        }
        ::v-deep .el-tabs__active-bar {
            height: 4px;
            border-radius: 2px;
        }
        ::v-deep .el-icon-date:before {
            content: '\21C5'
        }
    }
    .rank-table {
        display: flex;
        flex-direction: column;
        margin: 28px 0 30px 0;

        .table-calss-title {
            height: 18px;
            font-size: 18px;
            color: #333333;
        }
        .rank-table-item {
            margin-top: 10px;
            border: 1px solid #DADCE7;
        }
        .table-title {
            font-size: 16px;
            color: #332D42;
            margin: 22px 0 10px 30px;
        }
    }
}
</style>
